import AOS from 'aos';
import 'aos/dist/aos.css';
import teamPlay from '../../assets/images/team-play.png';
import problemSolve from '../../assets/images/problem-solve.png';
import discovery from '../../assets/images/discovery.png';
import { useMobile } from '../../utils/detectSource';
import './style.scss';

AOS.init({
  once: true,
});

const HowItWorks = () => {
  return (
    <div className="why-section">
      <h2 className="why-section__title">
        <span className="why-section__highlight">چرا</span> هفسین؟
      </h2>
      <div className="appbarWrapper ">
        <div
          className="why-card"
          data-aos-offset="100"
          data-aos={useMobile() ? 'fade-up' : 'fade-left'}
          data-aos-duration="2000"
        >
          <img src={problemSolve} alt="HIWmedia" className="why-card__img" />
          <p className="why-card__title">وام قرض الحسنه</p>
          <p className="why-card__content">
            توی هفسین به افرادی که به پول احتیاج دارند اما قدرت پس دادنش رو هم دارن وام میدیم و هر ماه به کمک کننده ها بخشی از پول رو برمی‌گردونیم!
          </p>
        </div>

        <div className="why-card" data-aos-offset="100" data-aos="fade-up" data-aos-duration="2000">
          <img src={discovery} alt="HIWmedia" className="why-card__img" />
          <p className="why-card__title">پروژه‌های نیکوکاری</p>
          <p className="why-card__content">هر ماه  پول های جمع شده تعهد هفسینی رو به یک پروژه اختصاص میدیم!</p>
        </div>

        <div
          className="why-card"
          data-aos-offset="100"
          data-aos={useMobile() ? 'fade-up' : 'fade-right'}
          data-aos-duration="2000"
        >
          <img src={teamPlay} alt="HIWmedia" className="why-card__img" />
          <p className="why-card__title">پویش های مناسبتی</p>
          <p className="why-card__content">در سال  یک سری رویداد خاص برای جشن عاطفه ها برای تهیه کتاب به نیازمندان انجام میدیم</p>
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
