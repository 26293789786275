import React from 'react';
import { useMediaQuery } from '@mui/material';
import './style.scss';

const AnimatedLogo = () => {
   const show = useMediaQuery('(min-width: 420px)') ? 'flex' : 'none'
  return (
    <h2 className="animated-logo" dir="ltr" >
      <span>H</span>
      <span>a</span>
      <span>f</span>
      <span>c</span>
      <span>i</span>
      <span>n</span>
      <span style={{ display: show , marginLeft:"15px"}}>c</span>
      <span style={{ display: show }}>h</span>
      <span style={{ display: show }}>a</span>
      <span style={{ display: show }}>r</span>
      <span style={{ display: show }}>i</span>
      <span style={{ display: show }}>t</span>
      <span style={{ display: show }}>y</span>
    </h2>
  );
};

export default AnimatedLogo;
