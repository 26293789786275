import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Tooltip, Grid } from '@mui/material';
import ReactLoading from 'react-loading';
import noPersonImg from '../../assets/images/noperson.png';
import apiInstance from '../../utils/axiosConfig';
import { baseUrl } from '../../utils/constants';
import { Typography } from '@mui/material';
import 'swiper/swiper-bundle.min.css';
import './style.scss';
import { Link } from 'react-router-dom';
import image from '../../assets/images/profile_2.png';

const BestTeachers = () => {
  const [topTeachers, setTopTeachers] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  useEffect(() => {
    // setApiLoading(true);
    // apiInstance.get(`${baseUrl}/accounts/instructors/top/`).then(res => {
    //   setTopTeachers(res.data);
    //   setApiLoading(false);
    // });
    setTopTeachers([
      { first_name: 'سید عماد', last_name: 'موسوی', username: 'emad12', image: image },
      { first_name: 'سید جواد', last_name: 'موسوی', username: 'emad12', image: image },
      { first_name: 'جواد', last_name: 'رهپیک', username: 'emad12', image: image },
      { first_name: 'سید عماد', last_name: 'موسوی', username: 'emad12', image: image },
      { first_name: 'سید جواد', last_name: 'موسوی', username: 'emad12', image: image },
      { first_name: 'جواد', last_name: 'رهپیک', username: 'emad12', image: image },
    ]);
  }, []);
  return (
    <div className="best-teachers">
      <h2 className="best-teachers__title">
        <span className="best-teachers__highlight">حامیان مالی</span> هفسین!
      </h2>
      {apiLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 'auto', marginTop: 96, marginBottom: 96 }}>
          <ReactLoading type="spinningBubbles" color="#EF006C" height={100} width={100} />
        </div>
      )}
      {!apiLoading && topTeachers.length > 0 && (
        <Grid container sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Grid item>
            <Swiper spaceBetween={10} slidesPerView={'auto'}>
              {topTeachers.map(BestTeacher => (
                <SwiperSlide key={BestTeacher.id}>
                  <Link to={`/public-profile/teacher/${BestTeacher.username}`}>
                    <Typography>
                      <img className="best-teachers__teacher-img" src={BestTeacher.image || noPersonImg} />
                      <span style={{ fontFamily: 'iranyekan' }} className="best-teachers__teacher-img__title">
                        {BestTeacher.first_name + ' ' + BestTeacher.last_name}
                      </span>
                    </Typography>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default BestTeachers;
